.loading-con {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 5%;
  animation: .6s cubic-bezier(.23, 1, .32, 1) both slide-in-blurred-top;
  display: flex;
  position: absolute;
  top: 37%;
  left: 5%;
  right: 0;
}

.loading-con-disabled {
  display: none;
}

.title-img {
  height: 21.4823vh;
}

.lds-ellipsis {
  width: 40px;
  height: 40px;
  margin-right: 100px;
  display: inline-block;
  position: relative;
}

.lds-ellipsis div {
  width: 13px;
  height: 13px;
  background: #fe2c55;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 33px;
}

.lds-ellipsis div:first-child {
  animation: .6s infinite lds-ellipsis1;
  left: 8px;
}

.lds-ellipsis div:nth-child(2) {
  animation: .6s infinite lds-ellipsis2;
  left: 8px;
}

.lds-ellipsis div:nth-child(3) {
  animation: .6s infinite lds-ellipsis2;
  left: 32px;
}

.lds-ellipsis div:nth-child(4) {
  animation: .6s infinite lds-ellipsis3;
  left: 56px;
}

@keyframes slide-in-blurred-top {
  0% {
    transform-origin: 50% 0;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
    transform: translateY(-1000px)scaleY(2.5)scaleX(.2);
  }

  100% {
    transform-origin: 50%;
    -webkit-filter: blur();
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

/*# sourceMappingURL=index.b1146086.css.map */
